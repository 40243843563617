import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Spacer from "../components/Spacer"
import PageHeader from "../components/PageHeader"
import FlexibleContent from "../components/FlexibleContent"
import BlogPreview from "../components/BlogPreview"
import ServiceBox from "../components/ServiceBox"

export const blog = graphql`
  query($id: String!) {
    wpService(id: { eq: $id }) {
      id
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageHeaderFields {
        headerContent
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      flexibleGeneralContentFields {
        ...FlexibleContentService
      }
    }

    # Services
    allWpService(filter: { id: { ne: $id } }) {
      nodes {
        slug
        title
        servicesFields {
          summary
        }
      }
    }
  }
`
export default function BlogTemplate({
  data: {
    wpService: {
      featuredImage,
      pageHeaderFields: { headerContent },
      flexibleGeneralContentFields: { content: pageContent },
      seo,
    },
    allWpService: { nodes: otherServices },
  },
}) {
  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <FlexibleContent content={pageContent} />
      <Spacer size={32} when={{ md: 48 }} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Our Other Services</h2>
            <Spacer size={32} />
          </div>
        </div>
        <div className="row">
          {otherServices.map(({ slug, title, servicesFields: { summary } }) => (
            <div className="col-12 col-lg-4" key={slug}>
              <ServiceBox serviceDetails={{ slug, title, summary }} />
            </div>
          ))}
        </div>
      </div>
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
      <BlogPreview />
    </>
  )
}
